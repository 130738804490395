import { Component } from '@angular/core';
import { CookieSettingsModalComponent } from '../../cookie-settings-modal';
import { CookieSettingsDEComponent } from '../cookie-settings-de';

@Component({
    standalone: true,
    selector: 'ins-cookie-settings-modal-de',
    templateUrl: 'cookie-settings-modal-de.component.html',
    imports: [CookieSettingsModalComponent, CookieSettingsDEComponent],
})
export class CookieSettingsModalDEComponent {}
