import { Component, inject, Input } from '@angular/core';
import { WucListModule, WucModalModule } from '@inshared/website-ui-components';
import { NgIf } from '@angular/common';
import { CookieSettingsComponent } from '../../cookie-settings';
import { NativeRouterService } from '@inshared/shared/util';

@Component({
    standalone: true,
    selector: 'ins-cookie-settings-de',
    templateUrl: 'cookie-settings-de.component.html',
    styleUrl: '../../cookie-settings-content-base/cookie-settings-content-base.component.scss',
    imports: [WucListModule, NgIf, CookieSettingsComponent, WucModalModule],
})
export class CookieSettingsDEComponent {
    @Input() hideClose = false;

    public baseUrl = inject(NativeRouterService).baseUrl;
}
