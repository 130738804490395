import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, Renderer2, RendererFactory2, inject } from '@angular/core';
import { AppConfigService } from '@inshared/shared/core';
import { StorageService } from '@inshared/shared/util-storage';

@Injectable({
    providedIn: 'root',
})
export class BlueConicService {
    private readonly renderer: Renderer2;
    private readonly platformId = inject(PLATFORM_ID);
    private readonly document = inject(DOCUMENT);
    private readonly storage = inject(StorageService);
    private readonly appConfigService = inject(AppConfigService);

    private blueconicUrl: string | undefined = '';

    constructor() {
        this.renderer = inject(RendererFactory2).createRenderer(null, null);
    }

    public init(): void {
        const config = this.appConfigService.getConfig();
        this.blueconicUrl = config?.blueconicUrl;

        if (isPlatformServer(this.platformId) || !Boolean(this.blueconicUrl)) {
            return;
        }

        if (!this.hasPermissions() || this.hasBlueconicScript()) {
            return;
        }

        this.addScript();
    }

    public removeScript(): void {
        const element = this.getBlueconicScript();

        if (element === null) {
            return;
        }

        element.parentNode?.removeChild(element);
    }

    private addScript(): void {
        const csa = this.renderer.createElement('script');

        this.renderer.setAttribute(csa, 'type', 'text/javascript');
        this.renderer.setAttribute(csa, 'src', this.blueconicUrl ?? '');
        this.renderer.setAttribute(csa, 'id', 'blueconicScript');

        this.document.getElementsByTagName('head')[0].appendChild(csa);
    }

    private getBlueconicScript(): HTMLElement | null {
        return this.document.getElementById('blueconicScript');
    }

    private hasBlueconicScript(): boolean {
        return Boolean(this.getBlueconicScript());
    }

    private hasPermissions(): boolean {
        // cookiebar nog niet gezien, dus geen toestemming
        if (!this.storage.getItem('ls.cookie.setting')) {
            return false;
        }

        // gekozen voor compleet, dus wel toestemming
        return this.storage.getItem('ls.cookie.setting') === 'all';
    }
}
