<div class="tooltip"
    *ngIf="visible$ | async"
    [ngClass]="{
        'tooltip--left': orientation === 'left',
        'tooltip--right': orientation === 'right'
    }"
>
    <div class="tooltip__container"
        @tooltipAnimation
        [ngClass]="{
            'tooltip__container--left': orientation === 'left',
            'tooltip__container--right': orientation === 'right'
        }"
    >
        {{ content }}
    </div>
</div>
