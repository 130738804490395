<wuc-button *ngIf="!hideClose" type="clean" modal-close-btn title="Sluit">
    <wuc-icon-ui icon="cross-closed"></wuc-icon-ui>
</wuc-button>
<ng-content select="[slot=main]"/>
<div class="cookie-settings__items">
    <div class="cookie-settings__button cookie-settings__consent-all-button">
        <wuc-button
            [isFullWidth]="true"
            type="primary"
            (buttonClick)="onClick(cookieConsent.All)"
            i18n="@@feature-cookie.modal.button-all"
            [ariaLabel]=ariaLabelAll
        >Agree
        </wuc-button>
        <wuc-link
            [noWrap]="true"
            (clicked)="onMoreInfoClick(cookieConsent.All)"
            [class.cookie-settings__link--selected]="moreInfoOpened === cookieConsent.All"
        >
            <span i18n="@@feature-cookie.modal.more-info-link-all">More information about agreement</span>
            <wuc-icon-ui
                class="cookie-settings__link-icon"
                type="secondary"
                [icon]="moreInfoOpened === cookieConsent.All ? 'up-open' : 'bottom-open'"
            ></wuc-icon-ui>
        </wuc-link>
    </div>
    <div
        class="cookie-settings__consent-all-info"
        *ngIf="moreInfoOpened === cookieConsent.All">
        <wuc-balloon
            arrowPosition="topMiddle"
            backgroundStyle="secondary"
            padding="medium"
        >
            <ng-content select="[slot=all]"/>
        </wuc-balloon>
    </div>
    <div class="cookie-settings__button cookie-settings__consent-basic-button">
        <wuc-button
            [isFullWidth]="true"
            type="secondary"
            (buttonClick)="onClick(cookieConsent.Basic)"
            i18n="@@feature-cookie.modal.button-basic"
            [ariaLabel]=ariaLabelBasic
        >Refuse
        </wuc-button>
        <wuc-link
            [noWrap]="true"
            (clicked)="onMoreInfoClick(cookieConsent.Basic)"
            [class.cookie-settings__link--selected]="moreInfoOpened === cookieConsent.Basic"
        >
            <span i18n="@@feature-cookie.modal.more-info-link-basic">More information about refusing</span>
            <wuc-icon-ui
                class="cookie-settings__link-icon"
                type="secondary"
                [icon]="moreInfoOpened === cookieConsent.Basic ? 'up-open' : 'bottom-open'"
            ></wuc-icon-ui>
        </wuc-link>
    </div>
    <div
        class="cookie-settings__consent-basic-info"
        *ngIf="moreInfoOpened === cookieConsent.Basic">
        <wuc-balloon
            [arrowPosition]="(isMobile$ | async) ? 'topMiddle' : 'topLeft'"
            backgroundStyle="secondary"
            padding="medium"
        >
            <ng-content select="[slot=basic]"/>
        </wuc-balloon>
    </div>
</div>
