<ins-cookie-settings [hideClose]="hideClose">
    <!-- prettier-ignore -->
    <div slot="main">
        <p class="h2" i18n="@@feature-cookie.modal.title" id="dialog-title">InShared uses cookies</p>
        <p>
            InShared verwendet Cookies, um Ihnen einen besseren und persönlicheren Service zu bieten,
            wenn Sie mit uns in Kontakt stehen, zum Beispiel über unsere Website, über soziale Medien oder über Anzeigen auf anderen Websites.
        </p>
        <p>
            Zu diesem Zweck analysieren und kombinieren wir Ihr Surfverhalten mit den personenbezogenen Daten,
            die Sie online hinterlassen und die bei uns bekannt sind.
            Wir arbeiten dabei mit anderen Cookie-Anbietern auch außerhalb der EU zusammen. Erfahren Sie mehr über
            <a [href]="baseUrl + '/cookies'">Cookies</a>
            oder lesen Sie unsere Datenschutzerklärung
            <!-- prettier-ignore -->
            <span><a [href]="baseUrl + '/datenschutz'">„Datenschutz (Webseite)“</a>.</span>
        </p>

        <p class="h3">Stimmen Sie zu?</p>
        <p>
            Dann erteilen Sie Ihre Einwilligung für das Setzen von Analyse-, Affiliate-, Feedback-, Werbe- und Personalisierungs-Cookies sowie für die Verarbeitung Ihrer personenbezogenen Daten. Wenn Sie das nicht möchten, können Sie Ihre Cookie-Einstellungen ändern oder allein mit notwendigen Cookies fortfahren.
        </p>
        <p>
            Unsere Website verwendet  <a [href]="baseUrl + '/cookies'">2 Arten von Cookies</a>. Sie können selbst auswählen, welche Cookies Sie akzeptieren. Ihre Auswahl können Sie über den Link „Cookie-Einstellungen ändern“ unten auf der Website jederzeit anpassen. Da die Website ohne funktionale Cookies nicht ordnungsgemäß funktioniert, können diese nicht deaktiviert werden.
        </p>

    </div>

    <div slot="all">
        <div class="cookie-settings__lists">
            <div class="cookie-settings__list">
                <div class="h4">Was wir tun</div>
                <wuc-list icon="dot-small">
                    <wuc-list-item>Notwendige Cookies setzen, damit diese Website gut funktioniert</wuc-list-item>
                    <wuc-list-item>Anonyme Statistiken erheben</wuc-list-item>
                    <wuc-list-item>Beitragsberechnungen und Anmeldungen erfassen</wuc-list-item>
                    <wuc-list-item>Nutzerverhalten untersuchen</wuc-list-item>
                    <wuc-list-item>Personenbezogene Statistiken erheben</wuc-list-item>
                    <wuc-list-item>Maßgeschneiderte Informationen anzeigen</wuc-list-item>
                    <wuc-list-item>
                        Personalisierte Anzeigen auf anderen Websites und in den sozialen Netzwerken auf Basis Ihres
                        Nutzungsverhaltens schalten
                    </wuc-list-item>
                </wuc-list>
            </div>
        </div>
    </div>
    <div slot="basic">
        <div class="cookie-settings__lists">
            <div class="cookie-settings__list">
                <div class="h4">Was wir tun</div>
                <wuc-list icon="dot-small">
                    <wuc-list-item>Notwendige Cookies setzen, damit diese Website gut funktioniert</wuc-list-item>
                    <wuc-list-item>Anonyme Statistiken erheben</wuc-list-item>
                    <wuc-list-item>Beitragsberechnungen und Anmeldungen erfassen</wuc-list-item>
                </wuc-list>
            </div>
            <div class="cookie-settings__divider"></div>
            <div class="cookie-settings__list">
                <div class="h4">Was wir nicht tun</div>
                <wuc-list icon="dot-small">
                    <wuc-list-item>Nutzerverhalten untersuchen</wuc-list-item>
                    <wuc-list-item>Personenbezogene Statistiken erheben</wuc-list-item>
                    <wuc-list-item>Maßgeschneiderte Informationen anzeigen</wuc-list-item>
                    <wuc-list-item>Personalisierte Anzeigen auf Basis Ihres Nutzungsverhaltens schalten</wuc-list-item>
                </wuc-list>
            </div>
        </div>
    </div>
</ins-cookie-settings>
