import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppConfigInterface } from './app-config.interface';

@Injectable({
    providedIn: 'root',
})
export class AppConfigService {
    private readonly httpClient = inject(HttpClient);
    private readonly platformId = inject(PLATFORM_ID);
    private config: AppConfigInterface | null = null;

    init$(): Observable<AppConfigInterface | null> {
        if (isPlatformServer(this.platformId)) {
            return of(null);
        }
        return this.httpClient
            .get(`${window.location.origin}/assets/data/config.json`)
            .pipe(tap((config: any) => (this.config = config)));
    }

    getConfig(): AppConfigInterface | null {
        return this.config;
    }
}
