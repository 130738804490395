import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { Driver, NgForageConfig } from 'ngforage';
import { CookieModule } from 'ngx-cookie';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { httpInterceptorProviders } from '@app-de/core/http-interceptors';
import { CookieSettingsWallModule } from '@app-de/modals/cookie-settings-wall-modal/cookie-settings-wall.modal.module';

registerLocaleData(localeDe);

export function easingLogic(t: number, b: number, c: number, d: number): number {
    // easeInOutExpo easing
    if (t === 0) {
        return b;
    }
    if (t === d) {
        return b + c;
    }
    if ((t /= d / 2) < 1) {
        return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
    }
    return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
}
@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        NgxPageScrollModule,
        NgxPageScrollCoreModule.forRoot({
            duration: 800,
            easingLogic,
        }),
        CookieModule.forRoot(),
        CookieSettingsWallModule,
    ],
    exports: [LoadingBarHttpClientModule],
    providers: [{ provide: LOCALE_ID, useValue: 'de' }, Title, Meta, httpInterceptorProviders],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule, ngfConfig: NgForageConfig) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
        ngfConfig.configure({
            driver: [Driver.INDEXED_DB, Driver.LOCAL_STORAGE],
        });
    }
}
