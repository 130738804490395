import { ApiDatalayerInterface, DatalayerInterface } from '../interfaces';

const removeEmpty = (obj: ApiDatalayerInterface): ApiDatalayerInterface => {
    const data = Object.entries(obj)
        .filter(([k, v]) => v !== undefined)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v === Object(v) && !Array.isArray(v) ? removeEmpty(v) : v }), {});
    for (const k in data) {
        if (Object.keys(data[k]).length === 0 && typeof data[k] !== 'number' && !Array.isArray(data[k])) {
            delete data[k];
        }
    }
    return data;
};

export function datalayerEventMapper(event: DatalayerInterface): ApiDatalayerInterface {
    const data = {
        ecommerce: {
            products: event?.ecommerce?.products ?? undefined,
            add: {
                products: event?.ecommerce?.add?.products ?? undefined,
            },
            remove: {
                products: event?.ecommerce?.remove?.products ?? undefined,
            },
            detail: event?.ecommerce?.detail ?? undefined,
            purchase: {
                actionField: {
                    id: event?.ecommerce?.purchase?.actionField?.id ?? undefined,
                    affiliation: event?.ecommerce?.purchase?.actionField?.affiliation ?? undefined,
                    revenue: event?.ecommerce?.purchase?.actionField?.revenue ?? undefined,
                    coupon: event?.ecommerce?.purchase?.actionField?.coupon ?? undefined,
                },
                products: event?.ecommerce?.purchase?.products ?? undefined,
            },
            checkout_option: {
                actionField: {
                    option: event?.ecommerce?.checkoutOption?.actionField?.option ?? undefined,
                    step: event?.ecommerce?.checkoutOption?.actionField?.step ?? undefined,
                },
            },
            checkout: {
                actionField: {
                    option: event?.ecommerce?.checkout?.actionField?.option ?? undefined,
                    step: event?.ecommerce?.checkout?.actionField?.step ?? undefined,
                    id: event?.ecommerce?.checkout?.actionField?.id ?? undefined,
                    affiliation: event?.ecommerce?.checkout?.actionField?.affiliation ?? undefined,
                    revenue: event?.ecommerce?.checkout?.actionField?.revenue ?? undefined,
                    coupon: event?.ecommerce?.checkout?.actionField?.coupon ?? undefined,
                },
                products: event?.ecommerce?.checkout?.products ?? undefined,
            },
            currencyCode: event?.ecommerce?.currencyCode ?? undefined,
        },
        auto: {
            product_id: event?.car?.productId ?? undefined,
            persoon_leeftijd: event?.car?.personAge ?? undefined,
            persoon_gezinssamenstelling: event?.car?.personFamilyComposition ?? undefined,
            persoon_postcode: event?.car?.personZipcode ?? undefined,
            gekozen_schadevrije_jaren: event?.car?.chosenNoClaimYearsAmount ?? undefined,
            gekozen_kilometrage: event?.car?.chosenMileage ?? undefined,
            gekozen_leeftijd_voertuig: event?.car?.chosenAgeVehicle ?? undefined,
            gekozen_cataloguswaarde: event?.car?.chosenCatalogValue ?? undefined,
            gekozen_aanschafdatum_voertuig: event?.car?.chosenDateOfPurchase ?? undefined,
            gekozen_eerste_registratie_datum_voertuig: event?.car?.chosenFirstRegistrationDate ?? undefined,
            meest_gekozen: event?.car?.mostPopular ?? undefined,
            gekozen_merk: event?.car?.chosenBrand ?? undefined,
            gekozen_type: event?.car?.chosenType ?? undefined,
            gekozen_eigenaarstype: event?.car?.chosenOwnerType ?? undefined,
            gekozen_merk_type: event?.car?.chosenType ?? undefined,
        },
        woon: {
            product_id: event?.living?.productId ?? undefined,
            gekozen_bouwjaar: event?.living?.chosenYearOfConstruction ?? undefined,
            gekozen_eigenaar: event?.living?.chosenOwner ?? undefined,
            gekozen_beveiliging: event?.living?.chosenSecurity ?? undefined,
            gekozen_type_woning: event?.living?.chosenTypeOfProperty ?? undefined,
            persoon_postcode: event?.living?.personZipcode ?? undefined,
            persoon_leeftijd: event?.living?.personAge ?? undefined,
            persoon_gezinssamenstelling: event?.living?.personFamilyComposition ?? undefined,
        },
        moped: {
            product_id: event?.moped?.productId ?? undefined,
            style_trim_id: event?.moped?.styleTrimId ?? undefined,
            persoon_leeftijd: event?.moped?.personAge ?? undefined,
            persoon_gezinssamenstelling: event?.moped?.personFamilyComposition ?? undefined,
            persoon_postcode: event?.moped?.personZipcode ?? undefined,
            gekozen_schadevrije_jaren: event?.moped?.chosenNoClaimYearsAmount ?? undefined,
            gekozen_leeftijd_voertuig: event?.moped?.chosenAgeVehicle ?? undefined,
            gekozen_cataloguswaarde: event?.moped?.chosenCatalogValue ?? undefined,
            gekozen_merk: event?.moped?.chosenBrand ?? undefined,
            gekozen_type: event?.moped?.chosenType ?? undefined,
            gekozen_eigenaarstype: event?.moped?.chosenOwnerType ?? undefined,
        },
        motr: {
            product_id: event?.motor?.productId ?? undefined,
            persoon_leeftijd: event?.motor?.personAge ?? undefined,
            persoon_gezinssamenstelling: event?.motor?.personFamilyComposition ?? undefined,
            persoon_postcode: event?.motor?.personZipcode ?? undefined,
            gekozen_cataloguswaarde: event?.motor?.chosenCatalogValue ?? undefined,
            gekozen_schadevrije_jaren: event?.motor?.chosenNoClaimYearsAmount ?? undefined,
            gekozen_kilometrage: event?.motor?.chosenMileage ?? undefined,
            gekozen_eigenaarstype: event?.motor?.chosenOwnerType ?? undefined,
            gekozen_leeftijd_voertuig: event?.motor?.chosenAgeVehicle ?? undefined,
            gekozen_merk: event?.motor?.chosenBrand ?? undefined,
            gekozen_type: event?.motor?.chosenType ?? undefined,
        },
        cvan: {
            product_id: event?.caravan?.productId ?? undefined,
            persoon_leeftijd: event?.caravan?.personAge ?? undefined,
            persoon_gezinssamenstelling: event?.caravan?.personFamilyComposition ?? undefined,
            persoon_postcode: event?.caravan?.personZipcode ?? undefined,
            gekozen_cataloguswaarde: event?.caravan?.chosenCatalogValue ?? undefined,
            gekozen_leeftijd_voertuig: event?.caravan?.chosenAgeVehicle ?? undefined,
            gekozen_merk: event?.caravan?.chosenBrand ?? undefined,
            gekozen_type: event?.caravan?.chosenType ?? undefined,
            hail_roof_indication: event?.caravan?.hailRoofIndication ?? undefined,
            caravan_code: event?.caravan?.caravanCode ?? undefined,
        },
        fiet: {
            product_id: event?.bicycle?.productId ?? undefined,
            gekozen_merk: event?.bicycle?.chosenBrand ?? undefined,
            gekozen_type: event?.bicycle?.chosenType ?? undefined,
            gekozen_aanschafdatum_voertuig: event?.bicycle?.chosenDateOfPurchase ?? undefined,
            gekozen_aankoopwaarde: event?.bicycle?.chosenPurchaseValue ?? undefined,
            persoon_leeftijd: event?.bicycle?.personAge ?? undefined,
            persoon_gezinssamenstelling: event?.bicycle?.personFamilyComposition ?? undefined,
            persoon_postcode: event?.bicycle?.personZipcode ?? undefined,
        },
        ingelogd: event?.loggedIn ?? undefined,
        cookie_id: event?.cookieId ?? undefined,
        klant: event?.customer ?? undefined,
        product_id: event?.productId ?? undefined,
        skus: event?.skus ?? undefined,
        event: event?.event ?? undefined,
        eventCategory: event?.eventCategory ?? undefined,
        eventAction: event?.eventAction ?? undefined,
        eventLabel: event?.eventLabel ?? undefined,
        eventValue: event?.eventValue ?? undefined,
        'gtm.uniqueEventId': event?.['gtm.uniqueEventId'] ?? undefined,
        'gtm.scrollDirection': event?.['gtm.scrollDirection'] ?? undefined,
        'gtm.scrollThreshold': event?.['gtm.scrollThreshold'] ?? undefined,
        'gtm.scrollUnits': event?.['gtm.scrollUnits'] ?? undefined,
        'gtm.triggers': event?.['gtm.triggers'] ?? undefined,
        'gtm.start': event?.['gtm.start'] ?? undefined,
        pageName: event?.pageName ?? undefined,
        page: {
            path: event?.page?.path ?? undefined,
        },
    };

    return removeEmpty(data);
}
