import { Component, inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NativeRouterService } from '@inshared/shared/util';
import { CookieConsent } from '@inshared/shared/core';
import { BlueConicService } from '@inshared/shared/util-analytics';
import { StorageService } from '@inshared/shared/util-storage';
import { isPlatformBrowser } from '@angular/common';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter, map, Subscription } from 'rxjs';

@Component({
    standalone: true,
    selector: 'ins-cookie-settings-content-base',
    template: '',
})
export abstract class CookieSettingsContentBaseComponent implements OnDestroy {
    public baseUrl = inject(NativeRouterService).baseUrl;
    public isCookieSettingsWallModalVisible = false;
    public cookieConsent = CookieConsent;

    private platform = inject(PLATFORM_ID);
    private blueConicService = inject(BlueConicService);
    private storage: Storage = inject(StorageService);
    private router = inject(Router);

    private subscriptions = new Subscription();

    constructor() {
        // First check if the environment is a browser
        if (isPlatformBrowser(this.platform)) {
            this.subscriptions.add(
                this.router.events.pipe(
                    filter((event: Event) => event instanceof NavigationEnd),
                    map((event: Event) => event as NavigationEnd),
                ).subscribe(() => this.initCookiebar())
            );
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private initCookiebar(): void {
        const cookieValue = this.storage.getItem('ls.cookie.setting');
        const isValidCookieValue = (cookieValue: string): boolean => [this.cookieConsent.All, this.cookieConsent.Basic].includes(cookieValue as CookieConsent);
        this.blueConicService.init();
        // FIXME: AppRoutesEnum is country specific, we need a solution for this
        const isSpecialUrl = ['/cookies', '/privacy', '/datenschutz'].includes(this.router.url)
        // Check if a cookie is set, or we are on a special page, before opening the cookiewall
        if (!isSpecialUrl && (!cookieValue || !isValidCookieValue(cookieValue))) {
            this.isCookieSettingsWallModalVisible = true;
        }
    }
}
