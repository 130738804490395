import { Component } from '@angular/core';
import { WucModalModule } from '@inshared/website-ui-components';
import { CookieSettingsComponent } from '../cookie-settings';
import { CookieSettingsContentBaseComponent } from '../cookie-settings-content-base';

@Component({
    standalone: true,
    selector: 'ins-cookie-settings-modal',
    templateUrl: 'cookie-settings-modal.component.html',
    imports: [WucModalModule, CookieSettingsComponent],
})
export class CookieSettingsModalComponent extends CookieSettingsContentBaseComponent {}
