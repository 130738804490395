<ins-modal-close></ins-modal-close>

<ins-modal-title>Vorläufiges Passwort ändern</ins-modal-title>

<ins-modal-content>
    <form [formGroup]="form" role="form" (ngSubmit)="onSubmit()">
        <h3>Ihr Konto</h3>
        <cyno-field>
            <cyno-label>E-Mail-Adresse</cyno-label>
            <input cynoInput type="email" formControlName="email" id="modal-email" />
        </cyno-field>

        <cyno-field>
            <cyno-label>Temporäres Passwort</cyno-label>
            <input cynoInput type="password" formControlName="temporaryPassword" />
        </cyno-field>
        <ins-alert position="none" type="warning">
            Wählen Sie ein Passwort, das mindestens 8 Zeichen lang ist und mindestens 1 Ziffer enthält. Ihr Benutzername
            darf darin nicht vorkommen. Bitte achten Sie auch auf die Groß- und Kleinschreibung.
        </ins-alert>
        <br />
        <br />
        <p>Geben Sie bitte Ihr neues Passwort ein.</p>
        <cyno-field>
            <cyno-label>Neues Passwort</cyno-label>
            <cyno-password
                insShowPassword
                formControlName="newPassword"
                [passwordRequirements]="true"
                autocomplete="new-password"
            ></cyno-password>
            <cyno-error *ngIf="newPassword.touched && newPassword.hasError('required')">
                Sorry, Ihr Passwort entspricht nicht den Passwortrichtlinien.
            </cyno-error>
        </cyno-field>

        <cyno-field>
            <cyno-label>Passwort wiederholen</cyno-label>
            <cyno-password formControlName="repeatPassword"></cyno-password>
            <cyno-error *ngIf="repeatPassword.touched && repeatPassword.hasError('mismatchedPassword')">
                Sorry, Ihr Passwort entspricht nicht den Passwortrichtlinien.
            </cyno-error>
        </cyno-field>

        <ins-alert type="validation" *ngIf="errors.length > 0" [errors]="errors"></ins-alert>
    </form>
</ins-modal-content>

<ins-modal-actions alignment="end">
    <button class="btn btn--primary" [disabled]="submitting || form.invalid" (click)="onSubmit()">Weiter</button>
</ins-modal-actions>
