export enum AutoversicherungRoutesEnum {
    Autoversicherung = '',
    EAutoversicherung = 'e-auto-versicherung',
    KfzHaftpflichtversicherung = 'kfz-haftpflichtversicherung',
    AutoversicherungVergleichen = 'autoversicherung-vergleichen',
    Teilkasko = 'teilkasko',
    Vollkasko = 'vollkasko',
    Fahrerschutz = 'fahrerschutz',
    Pannenhilfe = 'pannenhilfe',
    Reviews = 'bewertungen',
    CarBrands = 'automarken',
    RatgeberZulassungsstelle = 'ratgeber-zulassungsstelle',
    AutoSicherheitRatgeber = 'auto-sicherheit-ratgeber',
    Fahrerassistenzsysteme = 'fahrerassistenzsysteme',
    Notbremsassistent = 'notbremsassistent',
    AdaptiveGeschwindigkeitsregelanlage = 'adaptive-geschwindigkeitsregelanlage',
    Fernlichtassistent = 'fernlichtassistent',
    Verkehrszeichenerkennung = 'verkehrszeichenerkennung',
    Spurwechselassistent = 'spurwechselassistent',
    FahrzeugscheinAufbau = 'fahrzeugschein-aufbau',
}

export enum AutoversicherungCitiesRoutesEnum {
    Koeln = 'koeln',
    Bonn = 'bonn',
    Berlin = 'berlin',
    Muenchen = 'muenchen',
    Hamburg = 'hamburg',
}
