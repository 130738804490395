import { Component, inject, Input } from '@angular/core';
import { CookieConsent } from '@inshared/shared/core';
import { NativeRouterService, RouteHelperService, WINDOW } from '@inshared/shared/util';
import { CookieStorage } from '@inshared/shared/util-storage';
import {
    WucBalloonModule,
    WucButtonModule,
    WucIconUiModule,
    WucLinkModule,
    WucListModule,
    WucModalModule
} from '@inshared/website-ui-components';
import { COOKIE_SETTING_KEY } from 'outshared-lib/core/constants';
import { AsyncPipe, NgIf } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs';
import { Router } from '@angular/router';
import { NewModalModule } from 'outshared-lib';

@Component({
    standalone: true,
    selector: 'ins-cookie-settings',
    templateUrl: 'cookie-settings.component.html',
    styleUrls: ['./cookie-settings.component.scss'],
    imports: [
        WucButtonModule,
        WucModalModule,
        WucLinkModule,
        WucListModule,
        NgIf,
        WucBalloonModule,
        WucIconUiModule,
        AsyncPipe,
        NewModalModule,
    ]
})
export class CookieSettingsComponent {
    @Input() hideClose = false;
    public isMobile$ = inject(BreakpointObserver)
        .observe(`(min-width: 576px)`)
        .pipe(map((state) => !state.matches));
    public baseUrl = inject(NativeRouterService).baseUrl;
    public cookieConsent = CookieConsent;

    public moreInfoOpened?: CookieConsent;

    private storage = inject(CookieStorage);
    private window = inject(WINDOW);
    private router = inject(Router);
    private routeHelperService = inject(RouteHelperService);
    public ariaLabelAll = $localize`:@@feature-cookie.modal.button-all-arialabel:Accept cookies`;
    public ariaLabelBasic = $localize`:@@feature-cookie.modal.button-basic-arialabel:Decline cookies`;

    public onMoreInfoClick(value: CookieConsent) {
        this.moreInfoOpened = this.moreInfoOpened === value ? undefined : value;
    }

    public onClick(value: CookieConsent) {
        this.saveSettings(value);
        this.clearModalOnRoute();
    }

    private saveSettings(cookieSettings: CookieConsent): void {
        this.storage.setItem(COOKIE_SETTING_KEY, cookieSettings, {
            path: '/',
        });
    }

    private clearModalOnRoute() {
        this.router.navigate(
            [
                this.routeHelperService.getCleanUrl(),
                { outlets: { modal: null } }
            ], {
                onSameUrlNavigation: 'reload'
            } ).finally(() => this.window.location?.reload());
    }
}
