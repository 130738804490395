<ngx-loading-bar color="#f173ac" [includeSpinner]="false"/>
<section class="cookiebar">
    <ins-cookie-settings-modal-de/>
</section>

<ins-header/>

<main role="main">
    <ins-breadcrumb-container *ngIf="!((seoService.routeData$ | async)?.['hideBreadcrumb'])"/>
    <router-outlet/>
</main>

<section class="section section--white section--bordered">
    <div class="container">
        <div class="wrapper">
            <div class="col-8">
                <ins-breadcrumb-bordered-container/>
            </div>
            <div class="col-4">
                <ins-slogan/>
            </div>
        </div>
    </div>
</section>

<ins-footer/>

<ins-chatbot-fixed-container/>
<router-outlet name="modal"/>
