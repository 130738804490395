import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AddressInterface } from './address.interface';
import { AddressFindResponseOptionsInterface } from '@app-de/shared/address-autocomplete/address-retrieve-option.interface';

@Component({
    selector: 'ins-address-autocomplete',
    templateUrl: './address-autocomplete.component.html',
    styleUrls: ['./address-autocomplete.component.scss'],
})
export class AddressAutocompleteComponent {
    @ViewChild('optionsRef') public optionRef: ElementRef<HTMLUListElement>;
    @ViewChild('addressIdInputRef', { static: true }) public addressIdInputRef: ElementRef<HTMLInputElement>;

    @Input() public autocompleteOptions: AddressFindResponseOptionsInterface[];
    @Input() public name: string;
    @Input() public value: string;
    @Input() public disabled: boolean = false;

    @Output() public readonly performAddressSearch = new EventEmitter();
    @Output() public readonly performRetrieveSearch = new EventEmitter();

    public get searchQuery(): string {
        return this.addressIdInputRef ? this.addressIdInputRef.nativeElement.value : null;
    }

    public onAddressSelection(id: string, item: AddressFindResponseOptionsInterface): void {
        this.performRetrieveSearch.emit({
            addressOption: item,
            optionsRef: this.optionRef,
            inputRef: this.addressIdInputRef,
        });
    }

    public onChange(): void {
        if (!this.searchQuery) {
            return;
        }
        this.performAddressSearch.emit({
            searchQuery: this.searchQuery,
            optionsRef: this.optionRef,
        });
    }

    public prefill(address: AddressInterface): void {
        if (!address) {
            return;
        }

        this.addressIdInputRef.nativeElement.value =
            `${address.street_name ? address.street_name + ' ' : ''}` +
            `${address.civic_number ? address.civic_number + (address.civic_number_suffix ? '' : ' ') : ''}` +
            `${address.civic_number_suffix ? address.civic_number_suffix + ' ' : ''}` +
            `${address.postal_code} ${address.city}`;
    }

    /**
     * onBackSpace function - event handler function for backpace key down
     */
    public onBackspace(): void {
        this.performAddressSearch.emit({
            searchQuery: this.searchQuery,
            optionsRef: this.optionRef,
        });
    }

    /**
     * We want to prevent DELETE, because that messes up the order for the formatters
     */
    public preventDelete(event: KeyboardEvent): void {
        event.preventDefault();
    }
}
